.section {
    padding: 105px 0;
    background-image: url('/images/section-contactus-bg.png');
    background-size: cover;
    text-align: center;

    h2 {
        margin-bottom: 33px;
        color: @white;
        font-size: 40px;
        font-weight: 300;
    }
}

@import '/Users/klikdokter/Personal/tekuton-id/tekuton-compro-web/src/styles/variables.less';