.footer-container {
    background: #000000;
    font-size: 16px;
    color: #ffffff;
    padding-top: 93px;
    padding-bottom: 134px;

    .company-info {
        @media only screen and (max-width: @screen-md) {
            justify-content: space-around;
            margin-bottom: 20px;
        }
    }

    .menu {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 16px;
        padding-bottom: 16px;

        h6 {
            margin: 0;
            font-size: inherit;
            font-weight: 500;
    
            a {
                color: #ffffff;
            }
        }
       
        @media only screen and (max-width: @screen-md) {
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: center;
        }
    }
}



@import '/Users/klikdokter/Personal/tekuton-id/tekuton-compro-web/src/styles/variables.less';