body {
    min-height: 100vh;
    font-family: @font-family;
    font-weight: 500;
    margin: 0;
    overflow: auto;

	@media only screen and (max-width: (@screen-xs)) {
		overflow-x: hidden;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

a {
    text-decoration: none;
}

.row {
    row-gap: 20px;    
}

::-webkit-scrollbar {
    width: 10px;
}
  
::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.02);
}
  
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 40px;
}

.animateSlideUp {
    opacity: 0;
}

.slideUp {
    transform: translateY(50px);
    opacity: 0;
    -webkit-animation: slideUp 1s forwards;
    -webkit-animation-delay: 0;
    animation: slideUp 1s forwards;
    animation-delay: 0;
}
@keyframes slideUp {
    100% { 
        transform: translateY(0);
        opacity: 1;
    }
}

.widthGrow {
    width: 0;
    -webkit-animation: widthGrow 1.5s forwards;
    -webkit-animation-delay: 0;
    animation: widthGrow 1.5s forwards;
    animation-delay: 0;
}
@keyframes widthGrow {
    100% { 
        width: 100%;
    }
}