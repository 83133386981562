.sidebar-mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100vh;
    width: 100vw;
}

.sidebar {
    position: fixed;
    right: 0;
    top: 0;
    right: -672px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100vh;
    width: 672px;
    max-width: 100vw;
    padding: 65px;
    padding-right: 200px;
    background: #EC1C24;
    overflow: auto;

    .close-btn {
        position: absolute;
        top: 22px;
        right: 40px;
        cursor: pointer;
    }
    
    @menu-count: 5;
    
    h6 {
        margin-bottom: 10px;

        a {
            color: @white;
            font-size: 64px;
            font-weight: 400;
            letter-spacing: 0.32px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &.hide {
        right: 0;
        -webkit-animation: slideOut 1s forwards;
        -webkit-animation-delay: @menu-count * 0.3s;
        animation: slideOut 1s forwards;
        animation-delay: @menu-count * 0.3s;

        h6 {
            transform: translateY(0);
            opacity: 1;

            .loop(@i) when (@i >= 0) {
                &:nth-child(@{i}) {
                    -webkit-animation: slideDown 0.6s forwards;
                    -webkit-animation-delay: (@menu-count - @i) * 0.3s;
                    animation: slideDown 0.6s forwards;
                    animation-delay: (@menu-count - @i) * 0.3s;
                }
                .loop(@i - 1);
            }
            .loop(@menu-count);
        }
    }

    &.show {
        right: -672px;
        -webkit-animation: slide 1s forwards;
        -webkit-animation-delay: 0s;
        animation: slideIn 1s forwards;
        animation-delay: 0s;

        h6 {
            transform: translateY(64px);
            opacity: 0;

            .loop(@i) when (@i <= @menu-count) {
                &:nth-child(@{i}) {
                    -webkit-animation: slideUp 0.6s forwards;
                    -webkit-animation-delay: (@i - 1) * 0.3s;
                    animation: slideUp 0.6s forwards;
                    animation-delay: (@i - 1) * 0.3s;
                }
                .loop(@i + 1);
            }
            .loop(1);
        }
    }


    @media only screen and (max-width: @screen-md) {
        & {
            padding: 49px;

            h6 {
                a {
                    font-size: 48px;
                }
            }
        }
    }

    @media only screen and (max-width: @screen-xs) {
        & {
            padding: 25px;
            
            .close-btn {
                top: 15px;
                right: 15px;
            }
        }
    }
}

@-webkit-keyframes slideIn {
    100% { right: 0; }
}

@keyframes slideIn {
    100% { right: 0; }
}

@-webkit-keyframes slideOut {
    100% { right: -672px; }
}

@keyframes slideOut {
    100% { right: -672px; }
}

@-webkit-keyframes slideUp {
    100% { 
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideUp {
    100% { 
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes slideDown {
    100% { 
        transform: translateY(64px);
        opacity: 0;
    }
}

@keyframes slideDown {
    100% { 
        transform: translateY(64px);
        opacity: 0;
    }
}

@import '/Users/klikdokter/Personal/tekuton-id/tekuton-compro-web/src/styles/variables.less';