.button {
    position: relative;
    min-width: 150px;
    height: 40px;
    padding: 10px 24px;
    border-radius: 6px;
    background: linear-gradient(113deg, #EC1C24 35.11%, #3C181E 78.5%);
    box-shadow: 0px 11px 26px -3px rgba(255, 255, 255, 0.08) inset, 0px 0px 6px 2px rgba(255, 182, 182, 0.45) inset;
    color: @white;
    text-align: center;
    text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.45);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.08px;
    border: none;
    outline: none;
    cursor: pointer;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        background-color: rgba(0,0,0,0.2);
        opacity: 0;
        transition: 0.3s opacity;
    }

    &:hover {
        &:before {
            opacity: 1;
        }
    }

    &:disabled {
        background: #bcbcbc;
        color: #7b838a;
        cursor: no-drop;

        &:before {
            display: none;
        }
    }
}

@import '/Users/klikdokter/Personal/tekuton-id/tekuton-compro-web/src/styles/variables.less';