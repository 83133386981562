.navbar {
    height: 86px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    background: transparent;
    border-bottom: 2px solid transparent;
    z-index: 999;

    &.scrolled {
        position: fixed;
        top: -86px;
        -webkit-animation: slide 0.5s forwards;
        -webkit-animation-delay: 2s;
        animation: slide 0.5s forwards;
        animation-delay: 0s;

        &.light {
            background: @black;
        }

        &.dark {
            background: @white;
        }
    }

    .menu {
        cursor: pointer;
    }
}

@-webkit-keyframes slide {
    100% { top: 0; }
}

@keyframes slide {
    100% { top: 0; }
}

@import '/Users/klikdokter/Personal/tekuton-id/tekuton-compro-web/src/styles/variables.less';