.font-weight-light {
	font-weight: 300 !important;
}

.font-weight-lighter {
	font-weight: lighter !important;
}

.font-weight-normal {
	font-weight: 400 !important;
}

.font-weight-bold {
	font-weight: 700 !important;
}

.font-weight-bolder {
	font-weight: bolder !important;
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.text-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.text-justify {
	text-align: justify !important;
}

.text-light {
	color: #f8f9fa !important;
}

.text-dark {
	color: #343a40 !important;
}

.text-white {
	color: @white !important;
}

.text-black-50 {
	color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
	color: rgba(255, 255, 255, 0.5) !important;
}

.font-italic {
	font-style: italic !important;
}

a.text-primary:hover,
a.text-primary:focus {
	color: @white !important;
	opacity: 0.7;
}

a.text-secondary:hover,
a.text-secondary:focus {
	color: #404040 !important;
	opacity: 0.7;
}

a.text-success:hover,
a.text-success:focus {
	color: #569117 !important;
	opacity: 0.7;
}

a.text-info:hover,
a.text-info:focus {
	color: #0096b3 !important;
	opacity: 0.7;
}

a.text-warning:hover,
a.text-warning:focus {
	color: #c45a0b !important;
	opacity: 0.7;
}

a.text-danger:hover,
a.text-danger:focus {
	color: #840111 !important;
	opacity: 0.7;
}

a.text-light:hover,
a.text-light:focus {
	color: #d4d4d4 !important;
	opacity: 0.7;
}

a.text-dark:hover,
a.text-dark:focus {
	color: black !important;
	opacity: 0.7;
}

.text-hide {
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.text-reset {
	color: inherit !important;
}

.lh-1 {
	line-height: 1 !important;
}

.lh-sm {
	line-height: 1.25 !important;
}

.lh-base {
	line-height: 1.5 !important;
}

.lh-lg {
	line-height: 2 !important;
}

.lh {
	&18 {
		line-height: 18px;
	}
}

.text-wrap {
	white-space: normal !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

.font-monospace {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
}

.text-decoration-none {
	text-decoration: none !important;
}

.text-underline {
	text-decoration: underline !important;
}

.text-break {
	word-break: break-word !important; // IE & < Edge 18
	overflow-wrap: break-word !important;
}

// font-size
.fs-xs {
	font-size: 0.7rem;
}
.fs-sm {
	font-size: 0.8rem;
}
.fs-md {
	font-size: 1rem;
}
.fs-lg {
	font-size: 1.2rem;
}
.fs-xl {
	font-size: 1.5rem;
}
.fs-xxl {
	font-size: 2rem;
}

.fs-10 {
	font-size: 10px !important;
}
.fs-11 {
	font-size: 11px !important;
}
.fs-12 {
	font-size: 12px !important;
}
.fs-13 {
	font-size: 13px !important;
}
.fs-14 {
	font-size: 14px !important;
}
.fs-15 {
	font-size: 15px !important;
}
.fs-16 {
	font-size: 16px !important;
}
.fs-17 {
	font-size: 17px !important;
}
.fs-18 {
	font-size: 18px !important;
}
.fs-19 {
	font-size: 19px !important;
}
.fs-20 {
	font-size: 20px !important;
}
.fs-21 {
	font-size: 21px !important;
}
.fs-22 {
	font-size: 22px !important;
}
.fs-23 {
	font-size: 23px !important;
}
.fs-24 {
	font-size: 24px !important;
}
.fs-25 {
	font-size: 25px !important;
}
.fs-26 {
	font-size: 26px !important;
}
.fs-27 {
	font-size: 27px !important;
}
.fs-28 {
	font-size: 28px !important;
}
.fs-29 {
	font-size: 29px !important;
}
.fs-30 {
	font-size: 30px !important;
}
.fs-40 {
	font-size: 40px !important;
}

.fw-400 {
	font-weight: 400 !important;
}
.fw-500 {
	font-weight: 500 !important;
}
.fw-600 {
	font-weight: 600 !important;
}
.fw-700 {
	font-weight: 700 !important;
}

@media (min-width: 576px) {
	.text-sm-left {
		text-align: left !important;
	}
	.text-sm-right {
		text-align: right !important;
	}
	.text-sm-center {
		text-align: center !important;
	}
	.text-sm-justify {
		text-align: justify !important;
	}

	.fs-sm-10 {
		font-size: 10px !important;
	}
	.fs-sm-11 {
		font-size: 11px !important;
	}
	.fs-sm-12 {
		font-size: 12px !important;
	}
	.fs-sm-13 {
		font-size: 13px !important;
	}
	.fs-sm-14 {
		font-size: 14px !important;
	}
	.fs-sm-15 {
		font-size: 15px !important;
	}
	.fs-sm-16 {
		font-size: 16px !important;
	}
	.fs-sm-17 {
		font-size: 17px !important;
	}
	.fs-sm-18 {
		font-size: 18px !important;
	}
	.fs-sm-19 {
		font-size: 19px !important;
	}
	.fs-sm-20 {
		font-size: 20px !important;
	}
	.fs-sm-21 {
		font-size: 21px !important;
	}
	.fs-sm-22 {
		font-size: 22px !important;
	}
	.fs-sm-23 {
		font-size: 23px !important;
	}
	.fs-sm-24 {
		font-size: 24px !important;
	}
	.fs-sm-25 {
		font-size: 25px !important;
	}
	.fs-sm-26 {
		font-size: 26px !important;
	}
	.fs-sm-27 {
		font-size: 27px !important;
	}
	.fs-sm-28 {
		font-size: 28px !important;
	}
	.fs-sm-29 {
		font-size: 29px !important;
	}
	.fs-sm-30 {
		font-size: 30px !important;
	}

	.fw-sm-400 {
		font-weight: 400 !important;
	}
	.fw-sm-500 {
		font-weight: 500 !important;
	}
	.fw-sm-600 {
		font-weight: 600 !important;
	}
	.fw-sm-700 {
		font-weight: 700 !important;
	}
}

@media (min-width: 768px) {
	.text-md-left {
		text-align: left !important;
	}
	.text-md-right {
		text-align: right !important;
	}
	.text-md-center {
		text-align: center !important;
	}
	.text-md-justify {
		text-align: justify !important;
	}

	.fs-md-10 {
		font-size: 10px !important;
	}
	.fs-md-11 {
		font-size: 11px !important;
	}
	.fs-md-12 {
		font-size: 12px !important;
	}
	.fs-md-13 {
		font-size: 13px !important;
	}
	.fs-md-14 {
		font-size: 14px !important;
	}
	.fs-md-15 {
		font-size: 15px !important;
	}
	.fs-md-16 {
		font-size: 16px !important;
	}
	.fs-md-17 {
		font-size: 17px !important;
	}
	.fs-md-18 {
		font-size: 18px !important;
	}
	.fs-md-19 {
		font-size: 19px !important;
	}
	.fs-md-20 {
		font-size: 20px !important;
	}
	.fs-md-21 {
		font-size: 21px !important;
	}
	.fs-md-22 {
		font-size: 22px !important;
	}
	.fs-md-23 {
		font-size: 23px !important;
	}
	.fs-md-24 {
		font-size: 24px !important;
	}
	.fs-md-25 {
		font-size: 25px !important;
	}
	.fs-md-26 {
		font-size: 26px !important;
	}
	.fs-md-27 {
		font-size: 27px !important;
	}
	.fs-md-28 {
		font-size: 28px !important;
	}
	.fs-md-29 {
		font-size: 29px !important;
	}
	.fs-md-30 {
		font-size: 30px !important;
	}
	.fs-md-40 {
		font-size: 40px !important;
	}
	.fs-md-48 {
		font-size: 48px !important;
	}

	.fw-md-400 {
		font-weight: 400 !important;
	}
	.fw-md-500 {
		font-weight: 500 !important;
	}
	.fw-md-600 {
		font-weight: 600 !important;
	}
	.fw-md-700 {
		font-weight: 700 !important;
	}
}

@media (min-width: 992px) {
	.text-lg-left {
		text-align: left !important;
	}
	.text-lg-right {
		text-align: right !important;
	}
	.text-lg-center {
		text-align: center !important;
	}
	.text-lg-justify {
		text-align: justify !important;
	}

	.fs-lg-10 {
		font-size: 10px !important;
	}
	.fs-lg-11 {
		font-size: 11px !important;
	}
	.fs-lg-12 {
		font-size: 12px !important;
	}
	.fs-lg-13 {
		font-size: 13px !important;
	}
	.fs-lg-14 {
		font-size: 14px !important;
	}
	.fs-lg-15 {
		font-size: 15px !important;
	}
	.fs-lg-16 {
		font-size: 16px !important;
	}
	.fs-lg-17 {
		font-size: 17px !important;
	}
	.fs-lg-18 {
		font-size: 18px !important;
	}
	.fs-lg-19 {
		font-size: 19px !important;
	}
	.fs-lg-20 {
		font-size: 20px !important;
	}
	.fs-lg-21 {
		font-size: 21px !important;
	}
	.fs-lg-22 {
		font-size: 22px !important;
	}
	.fs-lg-23 {
		font-size: 23px !important;
	}
	.fs-lg-24 {
		font-size: 24px !important;
	}
	.fs-lg-25 {
		font-size: 25px !important;
	}
	.fs-lg-26 {
		font-size: 26px !important;
	}
	.fs-lg-27 {
		font-size: 27px !important;
	}
	.fs-lg-28 {
		font-size: 28px !important;
	}
	.fs-lg-29 {
		font-size: 29px !important;
	}
	.fs-lg-30 {
		font-size: 30px !important;
	}

	.fw-lg-400 {
		font-weight: 400 !important;
	}
	.fw-lg-500 {
		font-weight: 500 !important;
	}
	.fw-lg-600 {
		font-weight: 600 !important;
	}
	.fw-lg-700 {
		font-weight: 700 !important;
	}
}

@media (min-width: 1200px) {
	.text-xl-left {
		text-align: left !important;
	}
	.text-xl-right {
		text-align: right !important;
	}
	.text-xl-center {
		text-align: center !important;
	}
	.text-xl-justify {
		text-align: justify !important;
	}

	.fs-xl-10 {
		font-size: 10px !important;
	}
	.fs-xl-11 {
		font-size: 11px !important;
	}
	.fs-xl-12 {
		font-size: 12px !important;
	}
	.fs-xl-13 {
		font-size: 13px !important;
	}
	.fs-xl-14 {
		font-size: 14px !important;
	}
	.fs-xl-15 {
		font-size: 15px !important;
	}
	.fs-xl-16 {
		font-size: 16px !important;
	}
	.fs-xl-17 {
		font-size: 17px !important;
	}
	.fs-xl-18 {
		font-size: 18px !important;
	}
	.fs-xl-19 {
		font-size: 19px !important;
	}
	.fs-xl-20 {
		font-size: 20px !important;
	}
	.fs-xl-21 {
		font-size: 21px !important;
	}
	.fs-xl-22 {
		font-size: 22px !important;
	}
	.fs-xl-23 {
		font-size: 23px !important;
	}
	.fs-xl-24 {
		font-size: 24px !important;
	}
	.fs-xl-25 {
		font-size: 25px !important;
	}
	.fs-xl-26 {
		font-size: 26px !important;
	}
	.fs-xl-27 {
		font-size: 27px !important;
	}
	.fs-xl-28 {
		font-size: 28px !important;
	}
	.fs-xl-29 {
		font-size: 29px !important;
	}
	.fs-xl-30 {
		font-size: 30px !important;
	}

	.fw-xl-400 {
		font-weight: 400 !important;
	}
	.fw-xl-500 {
		font-weight: 500 !important;
	}
	.fw-xl-600 {
		font-weight: 600 !important;
	}
	.fw-xl-700 {
		font-weight: 700 !important;
	}
}

.line-height-1 {
	line-height: 1 !important;
}

.ls- {
	&1{
		letter-spacing: 0.15px;
	}
}

