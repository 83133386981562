@font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/helvetica-neue-5/HelveticaNeueUltraLight.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/helvetica-neue-5/HelveticaNeueThin.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/helvetica-neue-5/HelveticaNeueLight.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/helvetica-neue-5/HelveticaNeueRoman.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/helvetica-neue-5/HelveticaNeueMedium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/helvetica-neue-5/HelveticaNeueBold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/helvetica-neue-5/HelveticaNeueHeavy.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/helvetica-neue-5/HelveticaNeueBlack.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}